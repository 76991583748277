<template>
  <div id="editUser">
    <v-card>
      <v-form ref="form" @submit.prevent="update()">
        <v-card-title>Düzenle</v-card-title>
        <v-divider />
        <v-card-text>
          <v-text-field
            id="name"
            v-model="form.name"
            label="Ad"
            name="name"
            prepend-icon="label"
            type="text"
            required
            :rules="nameRules"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close">İptal Et</v-btn>
          <v-btn color="primary" type="submit" text :disabled="submitDisabled"
            >Güncelle</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import firebase from "@/plugins/firebase";

export default {
  props: {
    category: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    submitDisabled: false,
    form: {
      name: ""
    }
  }),
  computed: {
    nameRules() {
      return [v => !!v || "Lütfen adınızı yazınız"];
    }
  },
  methods: {
    close() {
      this.reset();
      this.$emit("close");
    },
    refresh() {
      this.$emit("refresh");
    },
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
      this.resetValidation();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async update() {
      if (this.validate()) {
        this.submitDisabled = true;
        this.$notify("Kategori güncelleniyor. Lütfen bekleyin...");

        try {
          const newCat = {
            name: this.form.name
          };

          await firebase
            .firestore()
            .doc(`/apps/kahev-akademi/categories/${this.category.id}`)
            .update(newCat);

          this.submitDisabled = false;
          this.refresh();
          this.$notify({
            type: "success",
            text: "Kategori başarıyla güncellendi."
          });
          this.close();
        } catch (err) {
          this.submitDisabled = false;

          this.$notify({
            type: "error",
            title: "Hata!",
            text: "Kullanıcı güncellenemedi."
          });

          console.error(`HATA: Kategori güncellenemedi. ${err}`);
        }
      }
    }
  },
  watch: {
    category(newVal) {
      this.form.name = newVal.name;
    }
  },
  mounted() {
    this.form.name = this.category.name;
  }
};
</script>
