<template>
  <div id="categories">
    <v-container class="my-4">
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Ara"
                single-line
                hide-details
              />
              <v-spacer />
              <v-btn
                color="primary"
                text
                @click.stop="
                  () => {
                    dialogContent = 'Add';
                    dialog = true;
                  }
                "
              >
                <v-icon class="mr-2">person_add</v-icon>
                <span>Kategori Ekle</span>
              </v-btn>
              <v-btn icon @click="getItems">
                <v-icon class="mr-2">refresh</v-icon>
              </v-btn>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              :loading="loading"
              loading-text="Yükleniyor..."
            >
              <template v-slot:[`item.dateCreated`]="{ item }">
                {{ item.dateCreated.toLocaleString("tr-TR") }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu close-on-content-click>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on">
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item @click="editItem(item)">
                      <v-list-item-icon>
                        <v-icon>create</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Düzenle</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="deleteItem(item)">
                      <v-list-item-icon>
                        <v-icon>delete</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Sil</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="dialog" max-width="600" persistent>
      <component
        :is="dialogContent"
        @close="dialog = false"
        :category="selectedItem"
        @refresh="getItems"
      />
    </v-dialog>

    <confirm ref="confirm" />
  </div>
</template>

<script>
import firebase from "@/plugins/firebase";
import Add from "./Add.vue";
import Edit from "./Edit.vue";
import Confirm from "@/components/Confirm";

export default {
  data() {
    return {
      search: "",
      loading: false,
      headers: [
        {
          text: "Ad",
          align: "start",
          filterable: true,
          value: "name",
          width: "70%"
        },
        { text: "Eğitim Sayısı", value: "count", width: "30%" },
        { text: "Oluşturulma", value: "dateCreated", width: "160px" },
        { text: "", value: "actions", sortable: false, width: "50px" }
      ],
      items: [],
      selectedItem: {},
      dialog: false,
      dialogContent: ""
    };
  },

  methods: {
    async getItems() {
      this.loading = true;

      try {
        // Get all categories
        const catQuery = await firebase
          .firestore()
          .collection("/apps/kahev-akademi/categories")
          .orderBy("name")
          .get();

        this.loading = false;
        this.items = [];

        if (!catQuery.empty) {
          catQuery.docs.forEach(cat => {
            this.items.push({
              id: cat.id,
              name: cat.data().name,
              count: cat.data().count,
              dateCreated: cat.data().dateCreated.toDate()
            });
          });
        }
      } catch (err) {
        console.error(`HATA: Kategori listesi alınamadı. ${err}`);

        this.loading = false;
        this.$notify({
          title: "Hata!",
          text: "Kategori listesi alınamadı.",
          type: "error"
        });
      }
    },

    async deleteItem(item) {
      const confirmation = await this.$refs.confirm.open(
        "Kategori Silme",
        "Bu işlem geri alınamaz. Devam etmek istediğinizden emin misiniz?",
        {
          color: "red"
        }
      );

      if (confirmation) {
        this.$notify("Kategori siliniyor. Lütfen bekleyin...");

        try {
          await firebase
            .firestore()
            .doc(`/apps/kahev-akademi/categories/${item.id}`)
            .delete();

          // Kategorilermiş eğitimleri güncelle
          const courseQ = await firebase
            .firestore()
            .collection("/apps/kahev-akademi/courses")
            .where("categories", "array-contains", item.id)
            .get();

          courseQ.docs.forEach(courseDoc => {
            const categories = courseDoc.data().categories;

            // Kategoriyi sil
            const i = categories.findIndex(c => c == item.id);
            categories.splice(i, 1);

            firebase
              .firestore()
              .doc(`/apps/kahev-akademi/courses/${courseDoc.id}`)
              .update({
                categories: categories
              });
          });

          this.$notify({
            text: "Kategori başarıyla silindi.",
            type: "success"
          });

          this.getItems();
        } catch (err) {
          console.error(`HATA: Kategori silinemedi. ${err}`);

          this.$notify({
            title: "Hata!",
            text: "Kategori silinemedi.",
            type: "error"
          });
        }
      }
    },

    async editItem(item) {
      this.selectedItem = item;

      this.$notify({
        clean: true
      });

      // Open dialog
      this.dialogContent = "Edit";
      this.dialog = true;
    }
  },

  mounted() {
    this.getItems();
  },

  components: {
    Add,
    Edit,
    Confirm
  }
};
</script>
