<template>
  <div id="addUser">
    <v-card>
      <v-form ref="form" @submit.prevent="add()">
        <v-card-title>Yeni Kategori</v-card-title>
        <v-divider />
        <v-card-text>
          <v-text-field
            id="name"
            v-model="form.name"
            label="Ad"
            name="name"
            prepend-icon="label"
            type="text"
            required
            :rules="nameRules"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close">İptal Et</v-btn>
          <v-btn color="primary" type="submit" text :disabled="submitDisabled"
            >Ekle</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import firebase from "@/plugins/firebase";

export default {
  data: () => ({
    form: {
      name: ""
    },
    submitDisabled: false
  }),
  computed: {
    nameRules() {
      return [v => !!v || "Lütfen kategori adını yazınız"];
    }
  },
  methods: {
    close() {
      this.reset();
      this.$emit("close");
    },
    refresh() {
      this.$emit("refresh");
    },
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      //this.$refs.form.reset();

      this.form.name = "";

      this.resetValidation();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async add() {
      if (this.validate()) {
        this.submitDisabled = true;
        this.$notify("Kategori oluşturuluyor, lütfen bekleyin...");

        try {
          const newCat = {
            name: this.form.name,
            count: 0,
            dateCreated: firebase.firestore.Timestamp.now(),
            disabled: false
          };

          await firebase
            .firestore()
            .collection("/apps/kahev-akademi/categories")
            .add(newCat);

          this.submitDisabled = false;
          this.refresh();
          this.$notify({
            text: "Yeni kategori oluşturuldu.",
            type: "success"
          });
          this.close();
        } catch (err) {
          console.error(`HATA: Kullanıcı oluşturulamadı. ${err}`);

          this.submitDisabled = false;
          this.$notify({
            type: "error",
            title: "Hata!",
            text: "Kategori oluşturulamadı."
          });
        }
      }
    }
  }
};
</script>
